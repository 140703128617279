export const FORMA_PAGAMENTO = {
  BOLETO: 'BOLETO',
  CREDITO: 'CREDITO',
  DEBITO: 'DEBITO',
  MANUAL: 'MANUAL',
  PIX: 'PIX'
}

export const FORMA_PAGAMENTO_LABEL = {
  BOLETO: 'Boleto',
  DEBITO: 'Débito',
  CREDITO: 'Crédito',
  MANUAL: 'Manual',
  PIX: 'Pix'
}

export const ORDERSTATUS = {
  ORCAMENTO_NOVO: 'ORCAMENTO_NOVO',
  ORCAMENTO_PRECIFICADO: 'ORCAMENTO_PRECIFICADO',
  ENVIADO: 'ENVIADO',
  SEPARANDO: 'SEPARANDO',
  PAGO: 'PAGO',
  EMENTREGA: 'EMENTREGA',
  ENTREGUE: 'ENTREGUE',
  CANCELADO: 'CANCELADO',
  PRONTO_RETIRADA: 'PRONTO_RETIRADA',
  AGUARDANDOPAGAMENTO: 'AGUARDANDOPAGAMENTO'
}
