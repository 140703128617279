<template>
  <div>
    <div>
      <div class="radioClasse">
        <label class="v-label theme--light">Tipo: </label>
        <div class="inputs">
          <span v-for="(gateway, index) in gatewayList" :key="index">
            <input
              type="radio"
              v-model="formaPagamentoType"
              @change="e => dadosTratados(e, 'formaPagamentoType')"
              :id="gateway.value"
              :value="gateway.value"
            />

            <label :for="gateway.value" class="v-label theme--light">
              {{ gateway.label }}
            </label>
          </span>
        </div>
      </div>

      <div v-if="formaPagamentoType === templateFormaPagamento.BOLETO">
        <FormText
          label="Instruções"
          placeholder="Instruções para o pagamento com boleto (opcional e máximo 255 caracteres)"
          :maxlength="255"
          v-model="instructions"
          @input="e => dadosTratados(e, 'instructions')"
        />

        <FormField
          label="Quantidade de dias para vencimento"
          placeholder="Quantidade de dias para vencimento"
          typeField="number"
          minField="0"
          v-model="expiration_days"
          personalizedMessage="Não foi possível salvar a forma de pagamento. É necessário digitar uma quantidade de dias válida."
        />
      </div>
      <FormaDePagamentoBandeirasForm
        :tipo="formaPagamentoType"
        :dadosFormaPagamento="dadosFormaPagamento"
        @type="dadosTratados"
        ref="formaDePamentoBandeirasForm"
      />

      <FormSelect
        label="Momento do pagamento"
        v-model="status_depende_pagamento"
        :options="momentoPagamento"
        :clearable="false"
      />

      <div v-if="showPreAutorizacao()">
        <FormCheckbox
          label="Pré Autorização e Captura (Momentos Distintos)"
          v-model="pre_autorizacao"
        />

        <div v-if="pre_autorizacao">
          <FormField
            label="Margem Adicional % para Pré-Autorização Captura"
            placeholder="Insira o valor (opcional)"
            helpMaxWidth="70%"
            typeField="number"
            minField="0"
            maxField="100"
            v-model="margem_adicional"
            @blur="rangeMargem"
            help="Percentual que será acrescido a solicitação
              de pré-autorização enviada a adquirente
              devido aos cenários de alteração de valores
              no faturamento. Caso ativo, é recomendado
              constar no termo de uso da loja."
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormText from '@/components/form/FormText'
import FormField from '@/components/form/FormField'
import FormSelect from '@/components/form/FormSelect'
import FormCheckbox from '@/components/form/FormCheckbox'
import FormaDePagamentoBandeirasForm from './FormaDePagamentoBandeirasForm'
import { FORMA_PAGAMENTO, ORDERSTATUS } from '../enum/forma-pagamento-enum'
import * as _ from 'lodash'

export default {
  components: {
    FormaDePagamentoBandeirasForm,
    FormField,
    FormText,
    FormSelect,
    FormCheckbox
  },
  emits: ['type', 'ready'],
  props: {
    gatewayList: {
      type: Array,
      default: () => [],
      required: true
    },
    gatewayEscolhido: {
      type: String,
      required: true
    },
    dadosFormaPagamento: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formaPagamentoType: this.gatewayEscolhido,
      expiration_days: null,
      templateFormaPagamento: FORMA_PAGAMENTO,
      instructions: '',
      momentoPagamento: [
        {
          text: 'Após a separação',
          value: [ORDERSTATUS.PRONTO_RETIRADA, ORDERSTATUS.PAGO]
        },
        {
          text: 'Antes da separação',
          value: [ORDERSTATUS.ENVIADO]
        }
      ],
      status_depende_pagamento: [ORDERSTATUS.PRONTO_RETIRADA, ORDERSTATUS.PAGO],
      pre_autorizacao: false,
      margem_adicional: null
    }
  },
  methods: {
    dadosTratados(e, tipo) {
      switch (tipo) {
        case 'formaPagamentoType':
          this.$emit('type', {
            tipo: 'formaPagamentoType',
            valor: e.target.value
          })
          break

        case 'instructions':
          this.$emit('type', { tipo: 'formaPagamentoInstructions', valor: e })
          break

        default:
          this.$emit('type', { tipo: e.tipo, valor: e.valor })
          break
      }
    },
    showPreAutorizacao() {
      const dados = this.dadosFormaPagamento
      const isHomologado = ['CIELO', 'REDECARD'].includes(
        _.get(dados.gateway, 'nome_provider', '')
      )
      return (
        (this.status_depende_pagamento || []).includes(ORDERSTATUS.ENVIADO) &&
        this.formaPagamentoType === this.templateFormaPagamento.CREDITO &&
        isHomologado
      )
    },
    rangeMargem(event) {
      if (event < 0) {
        this.margem_adicional = 0
      } else if (event > 100) {
        this.margem_adicional = 100
      }
    }
  },
  watch: {
    gatewayEscolhido(newValue) {
      if (newValue) {
        this.formaPagamentoType = newValue
        this.instructions = null
        this.$emit('type', { tipo: 'instructions', valor: null })
        this.expiration_days = null
        this.pre_autorizacao = null
        this.margem_adicional = null
        this.$nextTick(() => {
          this.$refs.formaDePamentoBandeirasForm.limparCampos()
        })
      }
    },
    ready(val) {
      this.$emit('ready', val)
    },
    expiration_days() {
      this.$emit('type', {
        tipo: 'expiration_days',
        valor: this.expiration_days
      })
    },
    status_depende_pagamento() {
      this.$emit('type', {
        tipo: 'status_depende_pagamento',
        valor: this.status_depende_pagamento
      })
    },
    pre_autorizacao() {
      this.$emit('type', {
        tipo: 'pre_autorizacao',
        valor: this.pre_autorizacao
      })
    },
    margem_adicional() {
      this.$emit('type', {
        tipo: 'margem_adicional',
        valor: this.margem_adicional
      })
    }
  },
  created() {
    const dados = this.dadosFormaPagamento

    if (dados) {
      this.formaPagamentoType = dados.type
      this.instructions = dados.instructions
      this.expiration_days = dados.expiration_days
      this.status_depende_pagamento = dados.status_depende_pagamento.map(
        status => (ORDERSTATUS[status] ? ORDERSTATUS[status] : status)
      )
      this.pre_autorizacao = dados.pre_autorizacao
      this.margem_adicional = dados.margem_adicional
    }
  }
}
</script>

<style scoped>
.radioClasse {
  display: flex;
  align-items: center;
  margin-top: 26px;
  margin-bottom: 26px;
}

.inputs {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.inputs span input {
  border: 0px;
  width: 25px;
  height: 30px;
  vertical-align: middle;
}

.inputs span label {
  padding-left: 10px;
}
</style>
