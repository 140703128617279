<template>
  <div
    v-if="
      tipo === templateFormaPagamento.CREDITO ||
        tipo === templateFormaPagamento.DEBITO
    "
  >
    <FormCheckbox
      label="Permite parcelamento"
      v-model="allow_installments"
      class="parcelamento"
      v-show="tipo === 'CREDITO'"
    />

    <div class="bandeiras">
      <label class="v-label theme--light">Bandeiras aceitas:</label>
      <FormCheckbox
        label="Aceitar todas"
        style="padding-left: 20px;"
        v-model="qualquer_bandeira"
      />

      <v-container class="bg-surface-variant" v-if="!qualquer_bandeira">
        <v-row no-gutters>
          <v-col cols="6" v-for="bandeira in bandeiras" :key="bandeira">
            <v-sheet class="pa-2 ma-2">
              <FormCheckbox
                :label="bandeira"
                :val="bandeira"
                v-model="bandeiras_cartao"
              />
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div v-show="tipo === 'CREDITO' && allow_installments">
      <FormSelect
        label="Quantidade máxima de parcelas"
        v-model="max_installment_quantity"
        :options="gerarParcelas('parcela')"
        :clearable="false"
      />

      <FormField
        label="Valor mínimo da parcela"
        v-model="minInstallmentOrder"
        :money="quant"
      />

      <FormSelect
        label="Definir parcela padrão"
        v-model="default_installment"
        :options="gerarParcelas('vez', max_installment_quantity)"
        :clearable="false"
      />

      <FormCheckbox
        v-show="allow_installments"
        label="Não permitir alteração da parcela padrão"
        v-model="deny_change_default_installment"
      />
    </div>
  </div>
</template>

<script>
import FormCheckbox from '@/components/form/FormCheckbox'
import FormSelect from '@/components/form/FormSelect'
import FormField from '@/components/form/FormField'
import { FORMA_PAGAMENTO } from '../enum/forma-pagamento-enum'
import * as _ from 'lodash'

export default {
  components: {
    FormCheckbox,
    FormSelect,
    FormField
  },
  props: {
    tipo: {
      type: String,
      required: true
    },
    dadosFormaPagamento: {
      type: Object,
      required: true
    }
  },
  emits: ['type'],
  data() {
    return {
      allow_installments: false,
      bandeiras: [
        'Visa',
        'Master',
        'Diners',
        'Amex',
        'Hipercard',
        'Elo',
        'Mastercard Maestro',
        'JCB',
        'Aura',
        'Discover'
      ],
      qualquer_bandeira: true,
      parcelas: [],
      max_installment_quantity: '1',
      minInstallmentOrder: 0,
      quant: {
        decimal: ',',
        thousands: ' ',
        prefix: 'R$ ',
        suffix: '',
        default: '',
        precision: 2,
        masked: true
      },
      default_installment: '1',
      deny_change_default_installment: false,
      bandeiras_cartao: [],
      templateFormaPagamento: FORMA_PAGAMENTO,
      showPreAutorizacao: false
    }
  },
  methods: {
    gerarParcelas(tipo, quantidadePadrao = 12) {
      const parcela = []

      for (let index = 0; index < quantidadePadrao; index++) {
        const nomeclatura =
          tipo === 'parcela'
            ? index === 0
              ? 'Parcela'
              : 'Parcelas'
            : index === 0
            ? 'Vez'
            : 'Vezes'

        parcela.push({
          text: `${index + 1} - ${nomeclatura}`,
          value: `${index + 1}`
        })
      }

      return parcela
    },
    limparCampos() {
      this.allow_installments = false
      this.qualquer_bandeira = true
      this.bandeiras_cartao = []
      this.minInstallmentOrder = 0
      this.max_installment_quantity = '1'
      this.default_installment = '1'
      this.deny_change_default_installment = false
    }
  },
  watch: {
    allow_installments() {
      this.$emit('type', {
        tipo: 'allow_installments',
        valor: this.allow_installments
      })
    },
    qualquer_bandeira() {
      this.$emit('type', {
        tipo: 'qualquer_bandeira',
        valor: this.qualquer_bandeira
      })
    },
    max_installment_quantity() {
      this.$emit('type', {
        tipo: 'max_installment_quantity',
        valor: this.max_installment_quantity
      })
    },
    minInstallmentOrder() {
      this.$emit('type', {
        tipo: 'minInstallmentOrder',
        valor: this.minInstallmentOrder
      })
    },
    default_installment() {
      this.$emit('type', {
        tipo: 'default_installment',
        valor: this.default_installment
      })
    },
    deny_change_default_installment() {
      this.$emit('type', {
        tipo: 'deny_change_default_installment',
        valor: this.deny_change_default_installment
      })
    },
    bandeiras_cartao() {
      this.$emit('type', {
        tipo: 'bandeiras_cartao',
        valor: this.bandeiras_cartao
      })
    }
  },
  created() {
    const dados = this.dadosFormaPagamento

    if (dados) {
      this.allow_installments = dados.allow_installments
      this.qualquer_bandeira = dados.qualquer_bandeira
      this.max_installment_quantity = String(dados.max_installment_quantity)
      this.minInstallmentOrder = dados.min_installment_order_value
      this.default_installment = _.get(dados, 'default_installment', null)
        ? String(dados.default_installment)
        : null
      this.deny_change_default_installment =
        dados.deny_change_default_installment
      this.bandeiras_cartao = dados.bandeiras_cartao
    }
  }
}
</script>

<style scoped>
.parcelamento {
  margin-top: 10px;
}

.bandeiras {
  margin-top: 10px;
}
</style>
