import { render, staticRenderFns } from "./FormaDePamentoGatewayForm.vue?vue&type=template&id=2db65545&scoped=true&"
import script from "./FormaDePamentoGatewayForm.vue?vue&type=script&lang=js&"
export * from "./FormaDePamentoGatewayForm.vue?vue&type=script&lang=js&"
import style0 from "./FormaDePamentoGatewayForm.vue?vue&type=style&index=0&id=2db65545&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2db65545",
  null
  
)

export default component.exports