<template>
  <SidePopup
    :title="titulo"
    @submit="salvarFormaPagamento"
    routeback="/fornecedor/cadastro/forma-pagamento"
  >
    <FormField
      label="Descrição"
      placeholder="Descrição da forma de pagamento"
      v-model="formaPagamento.description"
      :validation="$v.formaPagamento.description"
      personalizedMessage="Não foi possível salvar a forma de pagamento. É necessário digitar uma descrição."
    />

    <FormField
      label="ID forma de pagamento"
      placeholder="ID da forma de pagamento (opcional)"
      v-model="formaPagamento.id_payment_method_erp"
    />

    <FormField
      label="ID forma cobrança"
      placeholder="ID da forma de cobrança (opcional)"
      v-model="formaPagamento.id_cobranca_erp"
    />

    <FormField
      label="Mínimo do pedido"
      placeholder="Minimo do pedido ao usar a forma de pagamento (opcional)"
      v-model="minOrderModel"
      :money="quant"
    />

    <div class="checkBox">
      <FormCheckbox
        label="Permitir que a integração modifique o nome de exibição"
        v-model="formaPagamento.allow_erp_change"
        style="height: 100%;"
      />
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" class="ml-3">far fa-question-circle</v-icon>
        </template>
        {{ mensagensDeAjuda.permitirIntegracao }}
      </v-tooltip>
    </div>

    <FormSelect
      label="Gateway"
      placeholder="Selecione um gateway (opcional)"
      v-model="formaPagamento.idgateway"
      :options="gateways"
      :clearable="true"
    />

    <div class="panel-body" v-if="showTypeSelection">
      <FormaDePamentoGatewayForm
        :gatewayList="suporteFormaDePagamento()"
        :gatewayEscolhido="formaPagamento.type"
        :dadosFormaPagamento="formaPagamento"
        @type="dataFormaDePamentoGatewayForm"
      />
    </div>

    <FormField
      label="Cancelar pedidos em aguardando pagamento após (dias)"
      placeholder="Quantidade dias (opcional)"
      helpMaxWidth="70%"
      typeField="number"
      minField="0"
      maxField="120"
      v-model="formaPagamento.qtd_dias"
      :help="mensagensDeAjuda.cancelarPedidos"
    />

    <FormField
      label="Fator preço"
      placeholder="Fator preço"
      helpMaxWidth="70%"
      v-model="priceFactorModel"
      :help="mensagensDeAjuda.fatorPreco"
      :validation="$v.formaPagamento.price_factor"
      personalizedMessage="Não foi possível salvar a forma de pagamento. É necessário digitar um fator preço válido."
    />

    <FormField
      label="Fator preço secundário (apenas integração)"
      placeholder="Fator preço"
      v-model="secPriceFactorModel"
      :help="mensagensDeAjuda.fatorPrecoSecundario"
      :validation="$v.formaPagamento.sec_price_factor"
      :readonly="true"
    />

    <FormField
      label="Ordenação"
      placeholder="Ordenação"
      typeField="number"
      v-model="formaPagamento.payment_method_order"
      :help="mensagensDeAjuda.ordenacao"
    />

    <div class="checkBox">
      <FormCheckbox
        label="Não aplicar fator de preço desta forma de pagamento em produtos com politicas de preço fixo"
        v-model="formaPagamento.ignora_politica_preco_fixo"
        style="height: 100%;"
      />
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" class="ml-3">far fa-question-circle</v-icon>
        </template>
        {{ mensagensDeAjuda.fatorPrecoFixoCheckBox }}
      </v-tooltip>
    </div>

    <div class="checkBox">
      <FormCheckbox
        label="Não aplicar fator de preço desta forma de pagamento em produtos com politicas de fator de preço (%)"
        v-model="formaPagamento.ignora_politica_fator"
        style="height: 100%;"
      />
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" class="ml-3">far fa-question-circle</v-icon>
        </template>
        {{ mensagensDeAjuda.fatorPrecoCheckBox }}
      </v-tooltip>
    </div>

    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import FormField from '@/components/form/FormField'
import FormButton from '@/components/form/FormButton'
import FormCheckbox from '@/components/form/FormCheckbox'
import FormSelect from '@/components/form/FormSelect'
import FormaDePamentoGatewayForm from './FormaDePamentoGatewayForm.vue'
import { required, helpers } from 'vuelidate/lib/validators'
import { GATEWAY_LIST } from '@/store/actions/gateway'
import {
  FORMA_PAGAMENTO_BYID,
  FORMA_PAGAMENTO_ADD,
  FORMA_PAGAMENTO_UPDATE
} from '@/store/actions/formaPagamento'
import { validationMixin } from 'vuelidate'
import {
  FORMA_PAGAMENTO,
  ORDERSTATUS,
  FORMA_PAGAMENTO_LABEL
} from '../enum/forma-pagamento-enum'
import * as _ from 'lodash'

export default {
  name: 'FormaDePagamentoTable',
  components: {
    SidePopup,
    FormField,
    FormButton,
    FormCheckbox,
    FormSelect,
    FormaDePamentoGatewayForm
  },
  validations: {
    formaPagamento: {
      description: { required },
      price_factor: {
        customRequired: helpers.withParams(
          { type: 'customRequired' },
          function() {
            const validacao = valor => valor && valor < 0
            return !validacao(this.priceFactorModel)
          }
        )
      },
      sec_price_factor: {
        customRequired: helpers.withParams(
          { type: 'customRequired' },
          function() {
            const validacao = valor => valor && valor < 0
            return !validacao(this.secPriceFactorModel)
          }
        )
      }
    }
  },
  mixins: [validationMixin],
  data() {
    return {
      titulo: 'Forma de Pagamento',
      minOrderModel: null,
      priceFactorModel: 1,
      minInstallmentOrder: 0,
      quant: {
        decimal: ',',
        thousands: ' ',
        prefix: 'R$ ',
        suffix: '',
        default: '',
        precision: 2,
        masked: true
      },
      showTypeSelection: false,
      gateways: [],
      secPriceFactorModel: 1,
      idFornecedor: this.$store.getters.getFornecedorId,
      formaPagamento: null,
      acaoModal: '',
      mensagensDeAjuda: {
        permitirIntegracao:
          'Quando marcado, permite que a integração altere o nome de exibição da forma de pagamento',
        cancelarPedidos:
          'Criação de uma regra que cancele pedidos que estão com o status “Aguardando Pagamento” após X corridos. Evitar que um pedido fique aberto por um tempo longo e o cliente possa fazer o pedido com uma condição antiga.',
        fatorPreco:
          'O fator preço irá determinar qual o multiplicador do preço do produto será aplicado caso esta forma de pagamento seja utilizada. Exemplo: Produto A - R$ 10,00; Forma de Pagamento - Cartão Fator Preço com pagamento em Cartão - 1,05; Cálculo: Produto A x Fator Preço = Preço do Produto com a forma de pagamento utilizada R$10,00 x 1,05 = R$ 10,50',
        fatorPrecoSecundario: 'Controlado apenas pela integração',
        ordenacao:
          'Indica a ordem de como as formas de pagamento serão listadas para o seu cliente. A ordenação final será do menor "número da ordenação" juntamente com o menor fator de preço.',
        fatorPrecoCheckBox:
          'Quando o produto sofrer modificação de preço devido a uma politica de fator de preço (%), o fator de preço desta forma de pagamento não será aplicado, caso esta opção esteja marcada',
        fatorPrecoFixoCheckBox:
          'Quando o produto sofrer modificação de preço devido a uma politica de preço fixo, o fator de preço desta forma de pagamento não será aplicado, caso esta opção esteja marcada'
      }
    }
  },
  async created() {
    let { idformapagamento, idgateway } = this.$route.params
    idgateway === 'null' ? (idgateway = null) : ''

    this.resetFormaPagamento()

    try {
      const resp = await this.$store.dispatch(GATEWAY_LIST)

      if (resp) {
        resp.forEach(gateway => {
          this.gateways.push({
            text: gateway.name,
            value: gateway.idgateway,
            valueGateway: gateway
          })
        })
      }
    } catch (error) {
      if (error.msg) {
        this.$vueOnToast.pop('warning', error.msg)
      }

      this.$vueOnToast.pop(
        'warning',
        'Não foi possível listar os gateways. Erro no servidor.'
      )
    }

    if (idformapagamento && idformapagamento !== 'novo') {
      this.acaoModal = 'edit'
      await this.getFormaDePagamentoById(idformapagamento, idgateway)
    } else {
      this.acaoModal = 'create'
    }
  },
  methods: {
    resetFormaPagamento() {
      this.formaPagamento = {
        idformapagto: '',
        id_payment_method_erp: '',
        id_cobranca_erp: '',
        description: '',
        margem_adicional: null,
        pre_autorizacao: false,
        qtd_dias: '',
        price_factor: 1,
        sec_price_factor: 1,
        idgateway: null,
        gateway: null,
        min_order: '',
        allow_installments: false,
        max_installment_quantity: 1,
        deny_change_default_installment: false,
        default_installment: 1,
        min_installment_order_value: 0,
        status_depende_pagamento: [
          ORDERSTATUS.PRONTO_RETIRADA,
          ORDERSTATUS.PAGO
        ],
        type: FORMA_PAGAMENTO.MANUAL,
        expiration_days: null,
        instructions: null,
        allow_erp_change: false,
        payment_method_order: null,
        bandeiras_cartao: [],
        qualquer_bandeira: true,
        ignora_politica_preco_fixo: false,
        ignora_politica_fator: false
      }
    },
    async getFormaDePagamentoById(idformapagamento, idgateway) {
      try {
        const resp = await this.$store.dispatch(FORMA_PAGAMENTO_BYID, {
          idfornecedor: this.idFornecedor,
          idformaPagamento: idformapagamento
        })

        if (resp) {
          this.prepareFormaPagamento(resp, idgateway)
        }
      } catch (error) {
        if (error.msg) {
          this.$vueOnToast.pop('warning', error.msg)
        }

        this.$vueOnToast.pop(
          'warning',
          'Não foi possível buscar forma de pagamento pelo id.'
        )
      }
    },
    prepareFormaPagamento(formaPagamento, idgateway) {
      this.resetFormaPagamento()
      if (formaPagamento) {
        this.formaPagamento = {
          idformapagto: formaPagamento.idformapagto,
          id_payment_method_erp: formaPagamento.id_formapagto_erp,
          id_cobranca_erp: formaPagamento.id_cobranca_erp,
          description: formaPagamento.descricao,
          margem_adicional: formaPagamento.margem_adicional,
          pre_autorizacao: formaPagamento.pre_autorizacao,
          qtd_dias: formaPagamento.qtd_dias,
          price_factor:
            this.prepareNumberDecimalNotFixed(formaPagamento.fator_preco) || 1,
          sec_price_factor:
            this.prepareNumberDecimalNotFixed(formaPagamento.sec_fator_preco) ||
            1,
          idgateway: idgateway,
          gateway: null,
          min_order:
            this.prepareNumberDecimal(formaPagamento.min_pedido) || null,
          allow_installments: formaPagamento.permite_parcelamento,
          max_installment_quantity: formaPagamento.quant_maxima_parcelas,
          deny_change_default_installment:
            formaPagamento.nao_permite_alterar_parcela_padrao,
          default_installment: formaPagamento.parcela_padrao,
          min_installment_order_value:
            this.prepareNumberDecimal(formaPagamento.min_parcela) || null,
          status_depende_pagamento: _.get(
            formaPagamento,
            'status_depende_pagamento',
            [ORDERSTATUS.PRONTO_RETIRADA, ORDERSTATUS.PAGO]
          ),
          type: formaPagamento.tipo,
          expiration_days: formaPagamento.quant_dias_expiracao,
          instructions: formaPagamento.instrucoes,
          allow_erp_change: formaPagamento.permite_alteracao_erp,
          payment_method_order: formaPagamento.ordenacao,
          qualquer_bandeira:
            formaPagamento.bandeiras_cartao &&
            formaPagamento.bandeiras_cartao.length > 0
              ? false
              : true,
          bandeiras_cartao: formaPagamento.bandeiras_cartao || [],
          ignora_politica_preco_fixo: formaPagamento.ignora_politica_preco_fixo,
          ignora_politica_fator: formaPagamento.ignora_politica_fator
        }

        this.priceFactorModel = ('' + this.formaPagamento.price_factor).replace(
          /\./,
          ','
        )

        this.secPriceFactorModel = (
          '' + this.formaPagamento.sec_price_factor
        ).replace(/\./, ',')

        this.minOrderModel = ('' + this.formaPagamento.min_order).replace(
          /\./,
          ','
        )

        this.minInstallmentOrder = (
          '' + this.formaPagamento.min_installment_order_value
        ).replace(/\./, ',')
      }
    },
    prepareNumberDecimal(number) {
      if (!number) return
      return Number(
        Number(number)
          .toFixed(2)
          .replace(',', '.')
          .replace(/\s/g, '')
      ).toFixed(2)
    },
    prepareNumberDecimalNotFixed(number) {
      if (!number) return
      return number
        .toString()
        .replace(',', '.')
        .replace(/\s/g, '')
    },
    suporteFormaDePagamento() {
      if (this.formaPagamento && this.formaPagamento.gateway) {
        return this.formaPagamento.gateway.suporta.map(forma => ({
          label: FORMA_PAGAMENTO_LABEL[forma],
          value: forma
        }))
      }
    },
    dataFormaDePamentoGatewayForm(event) {
      const { tipo, valor } = event

      switch (tipo) {
        case 'formaPagamentoType':
          this.formaPagamento.type = valor
          break

        case 'formaPagamentoInstructions':
          this.formaPagamento.instructions = valor
          break

        default:
          if (tipo === 'minInstallmentOrder') {
            this[tipo] = valor
          } else {
            this.formaPagamento[tipo] = valor
          }
          break
      }
    },
    normalizeFatorPreco() {
      this.formaPagamento.price_factor = this.normalizeNumber(
        this.priceFactorModel
      )
      this.formaPagamento.sec_price_factor = this.normalizeNumber(
        this.secPriceFactorModel
      )
    },
    normalizeMinOrder() {
      this.formaPagamento.min_order = this.normalizeNumber(
        (this.minOrderModel || '').replace(this.quant.prefix, '')
      )
    },
    normalizeInstallmentMinOrder() {
      this.formaPagamento.min_installment_order_value = this.normalizeNumber(
        (this.minInstallmentOrder || '').replace(this.quant.prefix, '')
      )
    },
    normalizeNumber(n) {
      let numberCandidate = Number(
        String(n || '')
          .replace(/\s/g, '')
          .replace(/\,/, '.')
      )
      if (!numberCandidate || isNaN(numberCandidate)) {
        return null
      } else {
        return numberCandidate
      }
    },
    salvarFormaPagamento() {
      this.prepareDataToSend()
      this.$v.formaPagamento.$touch()
      if (this.$v.formaPagamento.$invalid) return

      const acao =
        this.acaoModal === 'create'
          ? FORMA_PAGAMENTO_ADD
          : FORMA_PAGAMENTO_UPDATE
      const msg = `Forma de pagamento 
        ${this.acaoModal === 'create' ? 'salva' : 'atualizada'} com sucesso.`

      this.$store
        .dispatch(acao, {
          idfornecedor: this.idFornecedor,
          formaPagamento: this.formaPagamento
        })
        .then(() => {
          this.$vueOnToast.pop('success', msg)
          this.$router.replace('/fornecedor/cadastro/forma-pagamento')
        })
        .catch(error => {
          if (error.msg) {
            this.$vueOnToast.pop('warning', error.msg)
          }

          this.$vueOnToast.pop('error', 'Error ao salvar a forma de pagamento.')
        })
    },
    prepareDataToSend() {
      this.normalizeFatorPreco()
      this.normalizeMinOrder()
      this.normalizeInstallmentMinOrder()

      if (this.formaPagamento.type !== FORMA_PAGAMENTO.BOLETO) {
        this.formaPagamento.instructions = null
        this.formaPagamento.expiration_days = null
      }

      if (this.formaPagamento.instructions) {
        this.formaPagamento.instructions = this.formaPagamento.instructions.trim()
      }

      this.formaPagamento.bandeiras_cartao = this.formaPagamento
        .qualquer_bandeira
        ? []
        : this.formaPagamento.bandeiras_cartao || []
    }
  },
  watch: {
    'formaPagamento.idgateway': {
      handler: function(idgateway) {
        if (!idgateway) {
          this.formaPagamento.allow_installments = false
          this.formaPagamento.type = FORMA_PAGAMENTO.MANUAL
          this.showTypeSelection = false
        } else {
          this.gateways.forEach(gateway => {
            const g = JSON.parse(JSON.stringify(gateway.valueGateway))

            if (g.idgateway === idgateway) {
              g.suporta.sort()
              this.formaPagamento.gateway = {
                ...g,
                suporta: g.suporta.sort()
              }
              this.showTypeSelection = true

              const support = _.get(g, 'suporta', [])

              if (support.length === 1) {
                this.formaPagamento.idgateway = g.idgateway
                this.formaPagamento.type = support[0]
              } else if (this.acaoModal === 'create') {
                this.formaPagamento.type = FORMA_PAGAMENTO.MANUAL
              }
            }
          })
        }
      },
      deep: true
    },
    priceFactorModel(newVal, oldVal) {
      const reg = /^[(0-9|\,|\.)]*$/
      if (newVal != null) {
        const result = reg.exec(newVal)
        this.priceFactorModel = result ? result[0] : oldVal
        this.formaPagamento.price_factor = this.priceFactorModel
      }
    },
    secPriceFactorModel(newVal, oldVal) {
      const reg = /^[(0-9|\,|\.)]*$/
      if (newVal != null) {
        const result = reg.exec(newVal)
        this.secPriceFactorModel = result ? result[0] : oldVal
        this.formaPagamento.sec_price_factor = this.secPriceFactorModel
      }
    }
  }
}
</script>

<style scoped>
.checkBox {
  height: 60px;
  display: flex;
  margin-bottom: 26px;
}

.panel-body {
  margin-bottom: 26px;
}
</style>
